export const AUTH_START="AUTH_START";
export const AUTH_FAIL="AUTH_FAIL";
export const AUTH_SUCCESS="AUTH_SUCCESS";
export const AUTH_LOGOUT="AUTH_LOGOUT";
export const AUTH_SET_CONFIG_PARAMS="AUTH_SET_CONFIG_PARAMS";
export const AUTH_SET_ID_TYPES="AUTH_SET_ID_TYPES";
export const SET_SESSION = "SET_SESSION";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const PENDING_POLICIES_ITEM_CHECK="PENDING_POLICIES_ITEM_CHECK";
export const PENDING_POLICIES_ITEM_UNCHECK="PENDING_POLICIES_ITEM_UNCHECK";
export const PENDING_POLICIES_ITEMS="PENDING_POLICIES_ITEMS";
export const PENDING_POLICIES_SET_ACTIVE_PAGE="PENDING_POLICIES_SET_ACTIVE_PAGE";
export const PENDING_POLICIES_SET_PER_PAGE="PENDING_POLICIES_SET_PER_PAGE";
export const PENDING_POLICIES_SET_TOTAL_ITEMS="PENDING_POLICIES_SET_TOTAL_ITEMS";
export const DOWNLOAD_SUMMARY_POLICY_CHECK="DOWNLOAD_SUMMARY_POLICY_CHECK";
export const DOWNLOAD_SUMMARY_POLICY_UNCHECK="DOWNLOAD_SUMMARY_POLICY_UNCHECK";
export const DOWNLOAD_SUMMARY_POLICIES="DOWNLOAD_SUMMARY_POLICIES";
export const PENDING_POLICIES_SET_TOTAL="PENDING_POLICIES_SET_TOTAL";
export const PENDING_POLICIES_SET_NUMBER_ITEMS="PENDING_POLICIES_SET_NUMBER_ITEMS";
export const PENDING_POLICIES_SET_VALUES="PENDING_POLICIES_SET_VALUES";
export const DOWNLOAD_SUMMARY_SET_VALUES="DOWNLOAD_SUMMARY_SET_VALUES";
export const DOWNLOAD_SUMMARY_SET_NUMBER_POLICIES="DOWNLOAD_SUMMARY_SET_NUMBER_POLICIES";
export const PENDING_POLICIES_UPDATE_ITEMS_STATUS="PENDING_POLICIES_UPDATE_ITEMS_STATUS";
export const DOWNLOAD_SUMMARY_UPDATE_POLICIES_STATUS="DOWNLOAD_SUMMARY_UPDATE_POLICIES_STATUS";
export const PENDING_POLICIES_SET_SEARCH_TERM="PENDING_POLICIES_SET_SEARCH_TERM";
export const PAID_POLICIES_SET_PER_PAGE="PAID_POLICIES_SET_PER_PAGE";
export const PAID_POLICIES_SET_ACTIVE_PAGE="PAID_POLICIES_SET_ACTIVE_PAGE";
export const PAID_POLICIES_SET_SEARCH_TERM="PAID_POLICIES_SET_SEARCH_TERM";
export const PAID_POLICIES_SET_TOTAL_ITEMS="PAID_POLICIES_SET_TOTAL_ITEMS";
export const PENDING_POLICIES_SET_TOTAL_VALUE_PORTFOLIO="PENDING_POLICIES_SET_TOTAL_VALUE_PORTFOLIO";
export const PENDING_POLICIES_SET_REFERENCE_FILE_ID="PENDING_POLICIES_SET_REFERENCE_FILE_ID";
export const ACTION_SET_IS_LOADING="SESSION_SET_IS_LOADING";
export const ACTION_SET_ERROR="SESSION_SET_ERROR";
export const PAYMENT_SET_ERROR="PAYMENT_SET_ERROR";
export const ACTION_UNSET_ERROR="SESSION_UNSET_ERROR";
export const PENDING_POLICIES_SET_IS_EMPTY_FILTER="PENDING_POLICIES_SET_IS_EMPTY_FILTER";
export const PENDING_POLICIES_SET_IS_NOT_EMPTY_FILTER="PENDING_POLICIES_SET_IS_NOT_EMPTY_FILTER";
export const SET_PAYMENT_METHOD="SET_PAYMENT_METHOD";
export const GENERIC_DATA_SET_COUNTRIES="GENERIC_DATA_SET_COUNTRIES";
export const GENERIC_DATA_SET_DEPARTMENTS="GENERIC_DATA_SET_DEPARTMENTS";
export const GENERIC_DATA_SET_CREDIT_CARD_TOP="GENERIC_DATA_SET_CREDIT_CARD_TOP";
export const GENERIC_DATA_SET_PAYMENT_PARAMETERS="GENERIC_DATA_SET_PAYMENT_PARAMETERS";
export const SET_BANKS_LIST="SET_BANKS_LIST";
export const SET_PAYMENT_STEP="SET_PAYMENT_STEP";
export const SET_PAYMENT_URL="SET_PAYMENT_URL";
export const SET_PAYMENT_HTML="SET_PAYMENT_HTML";
export const RESET="RESET";
export const RESET_SELECTED_POLICIES="RESET_SELECTED_POLICIES";
export const SET_PSE_PAYMENT_STATUS_DATA="SET_PSE_PAYMENT_STATUS_DATA";
export const SET_CREDIT_CARD_PAYMENT_STATUS_DATA="SET_CREDIT_CARD_PAYMENT_STATUS_DATA";
export const PORTAL_AUTHENTICATED="PORTAL_AUTHENTICATED";
export const PORTAL_ADMIN_AUTHENTICATED="PORTAL_ADMIN_AUTHENTICATED";
export const SET_INVOICE_LINK="SET_INVOICE_LINK";
export const PENDING_POLICIES_SET_FILTER_PARAMS="PENDING_POLICIES_SET_FILTER_PARAMS";
export const PAID_POLICIES_SET_FILTER_PARAMS="PAID_POLICIES_SET_FILTER_PARAMS";
export const SET_IS_REMOTE_PAYMENT="SET_IS_REMOTE_PAYMENT";
export const SET_EXTERNAL_PAYMENT_ORIGIN="SET_EXTERNAL_PAYMENT_ORIGIN";
export const SET_PAYMENT_IS_BLOCKED_URL="SET_PAYMENT_IS_BLOCKED_URL";
export const SET_PAYMENT_IS_BLOCKED_PAYMENT_BUTTON="SET_PAYMENT_IS_BLOCKED_PAYMENT_BUTTON";
export const CONFIRMATION_EMAIL_SENT_CREDIT_CARD="CONFIRMATION_EMAIL_SENT_CREDIT_CARD";
export const CONFIRMATION_EMAIL_SENT_PSE="CONFIRMATION_EMAIL_SENT_PSE";
export const SET_USER_HAS_UNCOMPLETED_PAYMENTS="SET_USER_HAS_UNCOMPLETED_PAYMENTS";
export const SET_EMAIL_RECEIPT="SET_EMAIL_RECEIPT";
export const SET_PSE_PAYMENT_RELATION_CONCEPT="SET_PSE_PAYMENT_RELATION_CONCEPT";
export const SET_CREDIT_CARD_PAYMENT_RELATION_CONCEPT="SET_CREDIT_CARD_PAYMENT_RELATION_CONCEPT";
export const SEND_EMAIL_PAYMENT_RETRY="SEND_EMAIL_PAYMENT_RETRY";
export const SET_EXTERNAL_PAYMENT_NOTIFICATION_DATA="SET_EXTERNAL_PAYMENT_NOTIFICATION_DATA";
export const SET_CLIENT_EMAIL="SET_CLIENT_EMAIL";
export const SET_PARAMETERS="SET_PARAMETERS";
export const SET_MESSAGE="SET_MESSAGE";
export const SET_PSE_DID_PAYMENT_SUCCEED="SET_PSE_DID_PAYMENT_SUCCEED";
export const SET_CREDIT_CARD_DID_PAYMENT_SUCCEED="SET_CREDIT_CARD_DID_PAYMENT_SUCCEED";
export const PAID_POLICIES_SET_IS_EMPTY_FILTER="PAID_POLICIES_SET_IS_EMPTY_FILTER";
export const PAID_POLICIES_SET_IS_NOT_EMPTY_FILTER="PAID_POLICIES_SET_IS_NOT_EMPTY_FILTER";
export const SET_GROUPED_PAYMENT_DOWNLOAD_RECEIPT_URL="SET_GROUPED_PAYMENT_DOWNLOAD_RECEIPT_URL" ;
export const SET_GROUPED_PAYMENT_EMAIL_SENT="SET_GROUPED_PAYMENT_EMAIL_SENT";
export const SET_GROUPED_PAYMENT_DATA="SET_GROUPED_PAYMENT_DATA";
export const RESET_GROUPED_PAYMENT_DATA="RESET_GROUPED_PAYMENT_DATA";
export const SET_GROUPED_PAYMENTS_FOR_TODAY="SET_GROUPED_PAYMENTS_FOR_TODAY";
export const SET_RECORDS_REPORTS="SET_RECORDS_REPORTS";
export const SET_REPORT_LINK="SET_REPORT_LINK";
export const SET_REPORT_SENT="SET_REPORT_SENT"; 
export const ERROR_USER_NOT_EXISTS="ERROR_USER_NOT_EXISTS"